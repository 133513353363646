var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getNoticeList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "SYS_DEFECT_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "sysDefectCd",
                    label: "결함유형",
                  },
                  model: {
                    value: _vm.searchParam.sysDefectCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "sysDefectCd", $$v)
                    },
                    expression: "searchParam.sysDefectCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "DEFECT_STATUS_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "defectStatusCd",
                    label: "처리상태",
                  },
                  model: {
                    value: _vm.searchParam.defectStatusCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "defectStatusCd", $$v)
                    },
                    expression: "searchParam.defectStatusCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: _vm.title,
                  tableId: "table",
                  columnSetting: false,
                  isFullScreen: false,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  isExcelDown: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getNoticeList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "결함 상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.saveable && _vm.dataeditable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveNotice,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.deleteable && _vm.dataeditable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.deleteUrl,
                                    isSubmit: true,
                                    param: _vm.data,
                                    mappingType: "DELETE",
                                    label: "삭제",
                                    icon: "remove",
                                  },
                                  on: {
                                    beforeAction: _vm.deleteNotice,
                                    btnCallback: _vm.deleteCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "제목",
                              name: "defectTitle",
                            },
                            model: {
                              value: _vm.data.defectTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "defectTitle", $$v)
                              },
                              expression: "data.defectTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              codeGroupCd: "SYS_DEFECT_CD",
                              type: "edit",
                              required: true,
                              itemText: "codeName",
                              itemValue: "code",
                              name: "sysDefectCd",
                              label: "결함유형",
                            },
                            model: {
                              value: _vm.data.sysDefectCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sysDefectCd", $$v)
                              },
                              expression: "data.sysDefectCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              codeGroupCd: "DEFECT_STATUS_CD",
                              type: "edit",
                              required: true,
                              itemText: "codeName",
                              itemValue: "code",
                              name: "defectStatusCd",
                              label: "처리상태",
                            },
                            model: {
                              value: _vm.data.defectStatusCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "defectStatusCd", $$v)
                              },
                              expression: "data.defectStatusCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              type: "editor",
                              label: "내용",
                              name: "defectContents",
                              editheight: 22,
                            },
                            model: {
                              value: _vm.data.defectContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "defectContents", $$v)
                              },
                              expression: "data.defectContents",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    staticStyle: { "margin-top": "5px !important" },
                    attrs: { title: "개발자 답변" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "답변내용",
                              rows: 4,
                              name: "defectAnswer",
                            },
                            model: {
                              value: _vm.data.defectAnswer,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "defectAnswer", $$v)
                              },
                              expression: "data.defectAnswer",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }