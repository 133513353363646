<template>
  <div>
    <c-search-box @enter="getNoticeList">
      <template slot="search">
        <div class="col-3">
          <c-select
            codeGroupCd="SYS_DEFECT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sysDefectCd"
            label="결함유형"
            v-model="searchParam.sysDefectCd"
          ></c-select>
        </div>
        <div class="col-3">
          <c-select
            codeGroupCd="DEFECT_STATUS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="defectStatusCd"
            label="처리상태"
            v-model="searchParam.defectStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          :title="title"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getNoticeList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="결함 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addNotice" /> -->
                <c-btn
                  v-if="editable && saveable && dataeditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveNotice"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-if="editable && deleteable && dataeditable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteNotice"
                  @btnCallback="deleteCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="제목"
                  name="defectTitle"
                  v-model="data.defectTitle">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="SYS_DEFECT_CD"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  name="sysDefectCd"
                  label="결함유형"
                  v-model="data.sysDefectCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="DEFECT_STATUS_CD"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  name="defectStatusCd"
                  label="처리상태"
                  v-model="data.defectStatusCd"
                ></c-select>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  type="editor"
                  label="내용"
                  name="defectContents"
                  :editheight="22"
                  v-model="data.defectContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
          <c-card title="개발자 답변" class="cardClassDetailForm" style="margin-top:5px !important">
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  label="답변내용"
                  :rows="4"
                  name="defectAnswer"
                  v-model="data.defectAnswer">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'defect-manage',
  data() {
    return {
      searchParam: {
        sysDefectCd: null,
        defectStatusCd: null,
      },
      grid: {
        columns: [
          {
            name: 'menuNm',
            field: 'menuNm',
            label: '메뉴명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sysDefectName',
            field: 'sysDefectName',
            label: '결함유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'defectTitle',
            field: 'defectTitle',
            label: '제목',
            align: 'left',
            sortable: true,
          },
          {
            name: 'defectStatusName',
            field: 'defectStatusName',
            label: '결함상태',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '등록자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '등록일시',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        sysDefectId: '',  // 결함번호
        sysMenuId: '',  // 메뉴 SEQ
        sysDefectCd: null,  // 결함유형
        defectTitle: '',  // 제목
        defectContents: '',  // 내용
        defectStatusCd: null,  // 결함상태
        defectAnswer: '',  // 답변
        regUserId: '',  // 등록자
        regDt: '',  // 등록일시
      },
      title: '결함 목록',
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      isAdmin: false,
      saveUrl: transactionConfig.sys.defect.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = true;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.defect.list.url;
      this.detailUrl = selectConfig.sys.defect.get.url;
      this.insertUrl = transactionConfig.sys.defect.insert.url;
      this.updateUrl = transactionConfig.sys.defect.update.url;
      this.deleteUrl = transactionConfig.sys.defect.delete.url;

      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getNoticeList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {sysDefectId: row.sysDefectId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addNotice() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        sysDefectId: '',  // 결함번호
        sysMenuId: this.$route.query.sysMenuId,  // 메뉴 SEQ
        sysDefectCd: null,  // 결함유형
        defectTitle: '',  // 제목
        defectContents: '',  // 내용
        defectStatusCd: 'DSC0000001',  // 결함상태
        defectAnswer: '',  // 답변
        regUserId: '',  // 등록자
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        sysDefectId: '',  // 결함번호
        sysMenuId: this.$route.query.sysMenuId,  // 메뉴 SEQ
        sysDefectCd: null,  // 결함유형
        defectTitle: '',  // 제목
        defectContents: '',  // 내용
        defectStatusCd: null,  // 결함상태
        defectAnswer: '',  // 답변
        regUserId: '',  // 등록자
      };
    },
    saveNotice() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteNotice() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.sysDefectId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ sysDefectId: result.data.sysDefectId })
      } else {
        this.getNoticeList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getNoticeList();
    },
  }
};
</script>
